import React from "react";

export const AddPageContext = React.createContext();
AddPageContext.displayName = 'AddPageContext';

export const initialState = {
    mapMarker: [],
    region: '',
    fileList: [],
    form: {
        name: '',
        phone: '',
        email: '',
        message: '',
        agree: true,
        valid: false
    }
};

export const addPageReducer = (state, action) => {
    switch (action.type) {
        case 'setMarker':
            return {
                ...state,
                ...action.payload
            };
        case 'setRegion':
            return {
                ...state,
                ...action.payload
            };
        case 'setFileList':
            return {
                ...state,
                ...action.payload
            };
        case 'setForm':
            return {
                ...state,
                ...action.payload
            };
        default:
            return state
    }
};