import * as React from "react"
import AppLayout from "../components/AppLayout";
import AddMapPoint from "../components/AddMapPoint";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkedAlt, faCamera, faFile, faCheck} from '@fortawesome/free-solid-svg-icons';
import {Col, Row, Space, Steps} from "antd";
import {useEffect, useReducer, useState} from "react";
import {BigButton} from "../components/Elements";
import AddPhotos from "../components/AddPhotos";
import AddForm from "../components/AddForm";
import {AddPageContext, addPageReducer, initialState} from "../reducers/reducer";
import SendMessage from "../components/SendMessage";
import {gql, useMutation} from '@apollo/client';
import Header from "../components/Header";


const AddPage = () => {

    const [state, dispatch] = useReducer(addPageReducer, initialState);
    const [step, setStep] = useState(0);
    const [mapStepStatus, setMapStepStatus] = useState("wait");
    const [photoStepStatus, setPhotoStepStatus] = useState("wait");
    const [formStepStatus, setFormStepStatus] = useState("wait");
    const [completeStepStatus, setCompleteStepStatus] = useState("wait");
    const [windowWidth, setWindowWidth] = useState();

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        //Без этого костыля не хотят шаги быть в вертикальном положении в мобильной версии :(
        let steps = document.getElementById("ant-steps-x");
        steps.classList.remove("ant-steps-vertical");
        steps.classList.add("ant-steps-horizontal");
    });

    const maxWindowWidth = 450;

    const ADD_REPORT = gql`
        mutation addReport(
            $fio: String!,
            $comment: String,
            $email: String,
            $phone: String,
            $coordinates: String!,
            $region: String,
            $photos: [String]
        ) {
            addReport(
                fio: $fio,
                comment: $comment,
                email: $email,
                phone: $phone,
                coordinates: $coordinates,
                region: $region,
                photos: $photos
            ) {
                id
            }
        }
    `;

    useEffect(() => {
        if (state.mapMarker.length > 0) {
            setMapStepStatus("finish");
        } else {
            setMapStepStatus("wait");
        }
    }, [state.mapMarker]);

    useEffect(() => {
        if (state.form.valid) {
            setFormStepStatus("finish");
        } else {
            setFormStepStatus("wait");
        }
    }, [state.form.valid]);

    useEffect(() => {
        if (step === 2) {
            setPhotoStepStatus("finish");
        }
    }, [step]);


    const [addReport] = useMutation(ADD_REPORT, {
        onCompleted: () => {
            setStep(step + 1);
            setCompleteStepStatus("finish");
        }
    });

    const steps = [
        {
            title: windowWidth > maxWindowWidth ? 'Карта' : null,
            status: mapStepStatus,
            icon: <FontAwesomeIcon icon={faMapMarkedAlt}/>,
            content: <AddMapPoint/>,
        },
        {
            title: windowWidth > maxWindowWidth ? 'Фото' : null,
            status: photoStepStatus,
            icon: <FontAwesomeIcon icon={faCamera}/>,
            content: <AddPhotos/>,
        },
        {
            title: windowWidth > maxWindowWidth ? 'Форма' : null,
            status: formStepStatus,
            icon: <FontAwesomeIcon icon={faFile}/>,
            content: <AddForm/>,
        },
        {
            title: windowWidth > maxWindowWidth ? 'Готово' : null,
            status: completeStepStatus,
            icon: <FontAwesomeIcon icon={faCheck}/>,
            content: <SendMessage/>,
        },
    ];

    const handleNextStep = (e) => {
        if (!e.disabled) {
            setStep(step + 1);
        }
    }

    const getPhotosArray = () => {
        let array = [];
        state.fileList.map(item => {
            if (item.response.filename !== undefined) {
                array.push(item.response.filename);
            }
        })
        return array;
    }

    const handleSendReport = () => {
        addReport({
            variables: {
                fio: state.form.name,
                comment: state.form.message,
                email: state.form.email,
                phone: state.form.phone,
                coordinates: state.mapMarker.join(";"),
                region: state.region,
                photos: getPhotosArray()
            }
        });
    }

    return (
        <AddPageContext.Provider value={{dispatch, state}}>
            <AppLayout>
                <Space size={20} direction={"vertical"} css={{width: "100%"}}>
                    <Header/>
                    <Row justify={"center"}>
                        <Col xxl={{span: 12}} xl={{span: 18}} xs={{span: 22}}>
                            <Steps
                                id={"ant-steps-x"}
                                direction={"horizontal"}
                                type={windowWidth > maxWindowWidth ? "navigation" : "default"}
                                onChange={step => setStep(step)}
                                current={step}
                            >
                                {steps.map(item => (
                                    <Steps.Step key={item.title} {...item}
                                                disabled={completeStepStatus === "finish" || item.status === "wait"}/>
                                ))}
                            </Steps>
                        </Col>
                    </Row>
                    <Row justify={"center"}>
                        {step > 0 && step < steps.length - 1 && (
                            <Col xxl={{span: 3}} xl={{span: 4}} sm={{span: 5}} xs={{span: 11}}>
                                <BigButton onClick={() => setStep(step - 1)}>
                                    Назад
                                </BigButton>
                            </Col>
                        )}
                        {step < steps.length - 2 && (
                            <Col xxl={{span: 3}} xl={{span: 4}} sm={{span: 5}} xs={{span: 11}}>
                                <BigButton disabled={steps[step].status !== "finish" && step !== 1} type="primary"
                                           onClick={handleNextStep}>
                                    Далее
                                </BigButton>
                            </Col>
                        )}
                        {step === steps.length - 2 && (
                            <Col xxl={{span: 3}} xl={{span: 4}} sm={{span: 5}} xs={{span: 11}}>
                                <BigButton type="primary" onClick={handleSendReport} disabled={!state.form.valid}>
                                    Отправить
                                </BigButton>
                            </Col>
                        )}
                    </Row>
                    {steps[step].content}
                </Space>
            </AppLayout>
        </AddPageContext.Provider>
    )
}

export default AddPage