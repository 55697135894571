import * as React from "react"
import {Row} from "antd";
import {Title} from "./Typography";
import {YMaps, Map, Placemark, SearchControl} from 'react-yandex-maps';
import {useContext, useEffect, useState} from "react";
import {AddPageContext} from "../reducers/reducer";
import {LoadingOutlined} from '@ant-design/icons';
import {usePosition} from './hooks/usePosition';

const AddMapPoint = () => {

    const {state, dispatch} = useContext(AddPageContext);
    const marker = state.mapMarker;
    const [mapCenter, setMapCenter] = useState([47.21426361523557, 39.7219220944648]);
    const [placemark, setPlacemark] = useState([]);
    const [mapLoaded, setMapLoaded] = useState(false);
    const [ymapsObj, setYmapsObj] = useState();

    const watch = false;
    const {
        latitude,
        longitude
    } = usePosition(watch);

    useEffect(() => {
        setAutoPositionPoint(latitude, longitude);
    }, [latitude, longitude]);

    useEffect(() => {
        if (ymapsObj && placemark.length) {
            setMarker(placemark);
            setRegion(placemark);
        }
    }, [placemark, ymapsObj]);

    const setAutoPositionPoint = (latitude, longitude) => {
        if (latitude !== undefined && longitude !== undefined && marker.length === 0) {
            let coords = [latitude, longitude];
            setPlacemark(coords);
            setMapCenter(coords);
        } else if (marker.length === 2) {
            setPlacemark(marker);
            setMapCenter(marker);
        }
    }

    const handleMarker = (e) => {
        setPlacemark(e.get('coords'));
    }

    const setMarker = (coords) => {
        dispatch({
            type: 'setMarker',
            payload: {
                mapMarker: coords
            }
        });
    }

    const setRegion = async (coords) => {
        let region = await ymapsObj.geocode(coords).then(res => {
            let firstGeoObject = res.geoObjects.get(0);
            let regionArr = firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas();
            return regionArr[0] !== undefined ? regionArr[0] : '';
        });
        dispatch({
            type: 'setRegion',
            payload: {
                region: region
            }
        });
    }

    const handleOnMapLoad = (ymaps) => {
        setYmapsObj(ymaps);
        setMapLoaded(true);
    }

    return (
        <>
            <Row justify={"center"}>
                <Title>
                    Отметьте точку на карте
                </Title>
            </Row>
            <Row justify={"center"}>
                {!mapLoaded && (
                    <LoadingOutlined style={{fontSize: 24}} spin/>
                )}
                <YMaps query={{apikey: process.env.GATSBY_YMAP_API_KEY}}>
                    <Map
                        width={"100%"}
                        height={"600px"}
                        onClick={handleMarker}
                        onLoad={handleOnMapLoad}
                        state={{center: mapCenter, zoom: 13}}
                        modules={['geocode']}
                    >
                        {(marker instanceof Array) && marker.length === 2 && (
                            <Placemark geometry={marker} defaultOptions={{iconColor: "#287a2c"}}/>)}
                        <SearchControl options={{noPlacemark: true}}/>
                    </Map>
                </YMaps>
            </Row>
        </>
    );
}

export default AddMapPoint;