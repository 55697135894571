import logo from "../images/logo.png";
import {Row} from "antd";
import * as React from "react";

const Header = () => {
    return (
        <Row justify={"center"} css={{height: '80px'}}>
            <div css={{
                height: "100%",
                padding: "10px",
                backgroundColor: "#287a2c"
            }}>
                <a href={'https://azovchernomor.ru/'} style={{height: '100%'}}>
                    <img src={logo} alt={"Logo"} style={{height: '90%', marginTop: '3px'}}/>
                </a>
            </div>
        </Row>
    );
}

export default Header;