import * as React from "react";
import {Checkbox, Col, Form, Input, Row, Typography} from 'antd';
import InputMask from 'react-input-mask';
import {useContext} from "react";
import {AddPageContext} from "../reducers/reducer";

const { Text } = Typography;

const layout = {
    labelCol: {
        xs: {
            span: 22
        },
        sm: {
            span: 4
        },
    },
    wrapperCol: {
        xs: {
            span: 22
        },
        sm: {
            span: 16
        },
    },
};

const validateMessages = {
    required: 'Обязательное поле!',
    types: {
        email: 'Неправильный формат поля!',
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 20,
            offset: 4,
        },
    },
};

const AddForm = () => {

    const {state, dispatch} = useContext(AddPageContext);
    const form = state.form;
    const [useForm] = Form.useForm();

    const handleOnChange = async (changedValues, allValues) => {
        let form = {...allValues.user}
        form.valid = await useForm.validateFields()
            .then(
                () => true,
                () => false
            )
        dispatch({
            type: 'setForm',
            payload: {
                form: form
            }
        })
    }

    return (
        <Row justify={"center"}>
            <Col xl={{span: 12}} xs={{span: 22}}>
                <Form
                    {...layout}
                    form={useForm}
                    validateTrigger={"onBlur"}
                    validateMessages={validateMessages}
                    name="user-form"
                    initialValues={{user: {...form}}}
                    onValuesChange={handleOnChange}
                >
                    <Form.Item
                        name={['user', 'name']}
                        label="Имя"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'phone']}
                        label="Телефон"
                        rules={[
                            {
                                required: true,
                            },
                            {
                                pattern: new RegExp('\^\\+7\\s\\(\\d{3}\\)\\s\\d{3}\-\\d{2}\-\\d{2}\$'),
                                message: 'Введите номер телефона в формате +7 (999) 999-99-99'
                            }
                        ]}
                    >
                        <InputMask mask="+7 (999) 999-99-99">
                            {(inputProps) => <Input
                                {...inputProps}
                                type={"tel"}
                            />}
                        </InputMask>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'email']}
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['user', 'message']} label="Сообщение">
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item
                        name={['user', 'agree']}
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject('Поставьте галочку!'),
                            },
                        ]}
                        {...tailFormItemLayout}
                    >
                        <Checkbox>
                            Я подтверждаю свое согласие на обработку моих персональных данных в соответствии с
                            требованиями
                            Федерального закона от 27 июля 2006 года № 152-ФЗ «О персональных данных».
                        </Checkbox>
                    </Form.Item>
                    <Form.Item {...tailFormItemLayout}>
                        <Text type="danger">*</Text> Поля, отмеченные звёздочкой обязательны к заполнению.<br/>
                        Ваши данные не будут опубликованы в общем доступе.
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default AddForm;