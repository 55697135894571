import * as React from "react"

export const Title = ({children}) => {
    return (
        <h1 css={{
            textAlign: "center",
            fontSize: "28px",
            lineHeight: "28px"
        }}>
            {children}
        </h1>
    );
}