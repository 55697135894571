import * as React from "react";
import {Row} from "antd";
import {Title} from "./Typography";
import {BigButton} from "./Elements";
import {Link} from "gatsby";

const SendMessage = () => {

    return (
        <>
            <Row justify={"center"}>
                <Title>
                    Ваш запрос успешно отправлен!
                </Title>
            </Row>
            <Row justify={"center"}>
                <Link to={"https://azovchernomor.ru/"}>
                    <BigButton type="primary">
                        Вернуться на главную!
                    </BigButton>
                </Link>
            </Row>
        </>
    );
};

export default SendMessage;